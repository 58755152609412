import { createContext, useContext, useReducer } from 'react'
import * as Sentry from '@sentry/nextjs'

export type GlobalFlags = {
  calculatorPageFlag: boolean
}

type Action =
  | { type: 'setErrorMsg'; payload: string }
  | { type: 'setBudgetRedirectPath'; payload?: string }
  | {
      type: 'setInitState'
      payload: {
        errorMsg: string
        // globalFlags: GlobalFlags
      }
    }
type Dispatch = (action: Action) => void
type State = {
  errorMsg: string
  budgetRedirectPath?: string
  // globalFlags: GlobalFlags
}
type AppStateProviderProps = {
  children: React.ReactNode
  globalFlags: GlobalFlags
}

const AppStateContext = createContext<
  | { state: State & { globalFlags: GlobalFlags }; dispatch: Dispatch }
  | undefined
>(undefined)

const appReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setInitState': {
      return {
        errorMsg: action.payload.errorMsg,
        // globalFlags: state.globalFlags,
      }
    }
    case 'setErrorMsg':
      return { ...state, errorMsg: action.payload }
    case 'setBudgetRedirectPath':
      return { ...state, budgetRedirectPath: action.payload }
    default: {
      Sentry.captureMessage('Unhandled action type')
      throw new Error(`Unhandled action type`)
    }
  }
}

const AppStateProvider = ({ children, globalFlags }: AppStateProviderProps) => {
  const [state, dispatch] = useReducer(appReducer, {
    errorMsg: '',
    // globalFlags,
  })

  return (
    <AppStateContext.Provider
      value={{
        state: {
          ...state,
          globalFlags,
        },
        dispatch,
      }}
    >
      {children}
    </AppStateContext.Provider>
  )
}

const useAppState = () => {
  const context = useContext(AppStateContext)
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppStateProvider')
  }
  return context
}

export { AppStateProvider, useAppState }
