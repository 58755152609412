import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'what-input'
import '../configureAmplify'
import { AppStateProvider, GlobalFlags } from '../services/context/AppContext'
import AuthProvider from '../services/context/AuthContext'
import { TrackingProvider } from '../services/context/TrackingContext'
import 'ui/styles/globals.css'

import { LinkedInInsightTag } from 'nextjs-linkedin-insight-tag'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10000,
    },
  },
})

if (process.env.NEXT_PUBLIC_USE_API_MOCKS === 'true') {
  require('../mocks')
}

type ComponentWithPageLayout<T> = AppProps<T> & {
  Component: AppProps["Component"] & {
    PageLayout?: React.ComponentType
  }
}

function MyApp({
  Component,
  pageProps,
}: ComponentWithPageLayout<{ globalFlags: GlobalFlags }>) {
  return (
    <AppStateProvider globalFlags={pageProps.globalFlags ?? {}}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <TrackingProvider>
            {process.env.NEXT_PUBLIC_LINKEDIN_PARTNER_ID && (
              <LinkedInInsightTag />
            )}
            {Component.PageLayout ? (
              <Component.PageLayout>
                <Component {...pageProps} />
              </Component.PageLayout>
            ) : (
              <Component {...pageProps} />
            )}
          </TrackingProvider>
        </QueryClientProvider>
      </AuthProvider>
    </AppStateProvider>
  )
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export default appWithTranslation(MyApp as any)
