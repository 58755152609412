import { Amplify } from '@aws-amplify/core'
import config from './config'

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: {
      domain: config.cognito.HOSTED_DOMAIN,
      scope: [
        'email',
        'profile',
        'phone',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: config.cognito.oauth.REDIRECT_SIGNIN,
      redirectSignOut: config.cognito.oauth.REDIRECT_SIGNOUT,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: 'customer',
        endpoint: config.customerApiGateway.URL,
        region: config.customerApiGateway.REGION,
      },
      {
        name: 'listing',
        endpoint: config.listingApiGateway.URL,
        region: config.listingApiGateway.REGION,
      },
      {
        name: 'approval',
        endpoint: config.approvalApiGateway.URL,
        region: config.approvalApiGateway.REGION,
      },
      {
        name: 'offer',
        endpoint: config.offerApiGateway.URL,
        region: config.offerApiGateway.REGION,
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: config.storage.CUSTOMER_APPROVAL_ASSETS_BUCKET,
      region: config.storage.REGION,
    },
  },
})
