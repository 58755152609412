import React, { useState, useEffect } from 'react'
import { Auth } from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'
import { API } from '@aws-amplify/api'

import { zendeskAPI } from '../../components/ChatLauncher'
import { useRouter } from 'next/router'
import { getPreferredHomesRoute } from '../../utils/constants'

export enum USER_TYPE {
  AGENT = 'agent',
  CUSTOMER = 'customer',
}

export enum COGNITO_GROUP {
  AGENT = 'Agents',
}

enum IDENTITY_PROVIDER {
  Google = 'Google',
}

export enum MARKET_PREFERENCE {
  RALEIGH_DURHAM = 'raleigh_durham_nc',
  ATLANTA_GA = 'atlanta_ga',
  NASHVILLE_TN = 'nashville_tn',
  OTHER = 'other',
  NO_ANSWER = 'no-answer',
}

export interface User {
  internalUsername: string
  email: string
  firstName: string
  lastName: string
  phoneNumber: string | null
  picture: string | null
  userType: USER_TYPE
  identityProvider: IDENTITY_PROVIDER | null
  marketPreference: MARKET_PREFERENCE
  allowSms: boolean
}

export interface SignUpParams
  extends Omit<User, 'identityProvider' | 'internalUsername' | 'picture'> {
  password: string
  allowSms: boolean
}

type ErrorMessage = {
  signup?: string
  signin?: string
  confirmAccount?: string
  updatePassword?: string
  passwordResetRequest?: string
  passwordResetSubmit?: string
}

export interface IAuthContextType {
  user: User | null
  isAuthenticated: boolean
  isOauth: boolean
  isLoading: boolean
  resentConfirmationCode: boolean
  unverifiedAccount: {
    email: string
    password: string
  }
  resetAccount: {
    email: string
  }
  errorMessage: ErrorMessage
  federatedRedirectUrl: string | null
  signIn: (p: { email: string; password: string }) => Promise<void>
  federatedSignIn: (p: { provider: string }) => Promise<void>
  signOut: () => Promise<void>
  signUp: (p: SignUpParams) => Promise<void>
  confirmAccount: (p: { code: string }) => Promise<void>
  resendConfirmationCode: () => Promise<void>
  updateUserAttributes: (p: Partial<SignUpParams>) => Promise<void>
  updatePassword: (currentValue: string, newValue: string) => Promise<void>
  forgotPasswordRequest: (p: { email: string }) => Promise<void>
  forgotPasswordSubmit: (p: {
    code: string
    newPassword: string
  }) => Promise<void>
  authRedirect: () => Promise<void>
}

export const AuthContext = React.createContext<IAuthContextType>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
  isOauth: false,
  unverifiedAccount: {
    email: '',
    password: '',
  },
  resetAccount: {
    email: '',
  },
  resentConfirmationCode: false,
  errorMessage: {},
  federatedRedirectUrl: null,
  signIn: async () => {
    /** */
  },
  federatedSignIn: async () => {
    /** */
  },
  signOut: async () => {
    /** */
  },
  signUp: async () => {
    /** */
  },
  confirmAccount: async () => {
    /** */
  },
  resendConfirmationCode: async () => {
    /** */
  },
  updateUserAttributes: async () => {
    /** */
  },
  updatePassword: async () => {
    /** */
  },
  forgotPasswordRequest: async () => {
    /** */
  },
  forgotPasswordSubmit: async () => {
    /** */
  },
  authRedirect: async () => {
    /** */
  },
})

export interface IAuthProviderProps {
  children: React.ReactNode
}

export const formattedPhoneNumber = (
  phoneNumber: string | null
): string | null => {
  if (phoneNumber === null) return null

  const withCountryCode = phoneNumber.match(/^\+/)
    ? phoneNumber
    : `+1${phoneNumber}`
  const noDashes = withCountryCode.replace(/-/g, '')

  return noDashes
}

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [unverifiedAccount, setUnverifiedAccount] = useState({
    email: '',
    password: '',
  })
  const [resetAccount, setResetAccount] = useState({
    email: '',
  })
  const [resentConfirmationCode, setResentConfirmationCode] = useState(false)
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({})
  const [federatedRedirectUrl, setFederatedRedirectUrl] = useState<
    string | null
  >(null)

  const router = useRouter()
  const { returnUrl } = router.query

  const fetchAuthUser = async () => {
    try {
      const fetchedUser = await Auth.currentAuthenticatedUser()
      setUser({
        internalUsername: fetchedUser.username,
        email: fetchedUser.attributes.email,
        firstName: fetchedUser.attributes.given_name,
        lastName: fetchedUser.attributes.family_name,
        phoneNumber: fetchedUser.attributes.phone_number,
        picture: fetchedUser.attributes.picture,
        userType:
          fetchedUser.attributes['custom:groupName'] === COGNITO_GROUP.AGENT
            ? USER_TYPE.AGENT
            : USER_TYPE.CUSTOMER,
        identityProvider: fetchedUser.username.match(/google_*/)
          ? IDENTITY_PROVIDER.Google
          : null,
        marketPreference: fetchedUser.attributes['custom:marketPreference'],
        allowSms: fetchedUser.attributes['custom:allowSms'] === 'true',
      })
      setIsLoading(false)
    } catch (err) {
      setUser(null)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchAuthUser()

    const postSignIn = async (username: string) => {
      /** @TODO move backend endpoints to JWT rendering this call unnecessary */
      await API.post('customer', '/sync_user', {
        body: { username },
      })

      /** @TODO login to Zendesk on chat demand */
      zendeskAPI.login()
    }

    const authListener = Hub.listen(
      'auth',
      async ({ payload: { event, data } }) => {
        console.debug('Authentication Event: ', event)

        switch (event) {
          case 'signIn':
            setErrorMessage((e) => ({
              ...e,
              signin: undefined,
              confirmAccount: undefined,
            }))
            await fetchAuthUser()
            await postSignIn(data.username)
            break
          case 'signOut':
            setUser(null)
            break
          case 'signIn_failure':
          case 'signUp_failure':
          case 'signUp':
            setIsLoading(false)
            setErrorMessage((e) => ({ ...e, signup: undefined }))
            break
          case 'customOAuthState':
            setFederatedRedirectUrl(data)
            break
          case 'forgotPassword':
            setIsLoading(false)
            setErrorMessage((e) => ({ ...e, passwordResetRequest: undefined }))
            break
          case 'forgotPasswordSubmit':
            setIsLoading(false)
            setErrorMessage((e) => ({ ...e, passwordResetSubmit: undefined }))
            break
          case 'forgotPasswordSubmit_failure':
          case 'forgotPassword_failure':
          default:
            await fetchAuthUser()
        }
      }
    )

    return () => {
      authListener()
    }
  }, [])

  const signIn = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => {
    setIsLoading(true)
    try {
      await Auth.signIn({ username: email, password })
    } catch (error) {
      if (error instanceof Error) {
        /** @TODO Scope error to auth */
        if (error.message.match(/User is not confirmed/)) {
          setUnverifiedAccount({ email, password })
        }
        setErrorMessage({
          ...errorMessage,
          signin: error.message || 'Error signing in',
        })
      }
      setIsLoading(false)
    }
  }

  const signUp = async ({
    email,
    password,
    firstName,
    lastName,
    phoneNumber,
    marketPreference,
    allowSms,
    userType,
  }: SignUpParams) => {
    setIsLoading(true)
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          given_name: firstName,
          family_name: lastName,
          phone_number: phoneNumber
            ? formattedPhoneNumber(phoneNumber)
            : undefined,
          'custom:marketPreference': marketPreference,
          'custom:allowSms': allowSms ? 'true' : 'false',
          ...(userType === USER_TYPE.AGENT
            ? { 'custom:groupName': COGNITO_GROUP.AGENT }
            : {}),
        },
      })
      setUnverifiedAccount({ email, password })
    } catch (error) {
      if (error instanceof Error) {
        /** @TODO Scope error to auth */
        let message = error.message
        if (message.match(/Unsupported email domain/)) {
          message = 'Unsupported email domain'
        }
        setErrorMessage({
          ...errorMessage,
          signup: message || 'Error signing up',
        })
      }
      setIsLoading(false)
    }
  }

  const federatedSignIn = async ({ provider }: { provider: string }) => {
    await Auth.federatedSignIn({
      customProvider: provider,
      customState:
        returnUrl && typeof returnUrl === 'string' ? returnUrl : undefined,
    })
  }

  const confirmAccount = async ({ code }: { code: string }) => {
    setIsLoading(true)
    setResentConfirmationCode(false)
    try {
      await Auth.confirmSignUp(unverifiedAccount?.email, code)
      await signIn({
        email: unverifiedAccount?.email,
        password: unverifiedAccount?.password,
      })
      setUnverifiedAccount({
        email: '',
        password: '',
      })
    } catch (error) {
      if (error instanceof Error) {
        /** @TODO Scope error to auth */
        setErrorMessage({
          ...errorMessage,
          confirmAccount: error.message || 'Error confirming account',
        })
      }
      setIsLoading(false)
    }
  }

  const resendConfirmationCode = async () => {
    await Auth.resendSignUp(unverifiedAccount.email)
    setResentConfirmationCode(true)
  }

  const updateUserAttributes = async (attributes: Partial<SignUpParams>) => {
    const updates = {
      ...(attributes.firstName !== undefined
        ? { given_name: attributes.firstName }
        : {}),
      ...(attributes.lastName !== undefined
        ? { family_name: attributes.lastName }
        : {}),
      ...(attributes.phoneNumber !== undefined
        ? { phone_number: formattedPhoneNumber(attributes.phoneNumber) }
        : {}),
      ...(attributes.marketPreference !== undefined
        ? { 'custom:marketPreference': attributes.marketPreference }
        : {}),
      ...(attributes.allowSms !== undefined
        ? { 'custom:allowSms': attributes.allowSms ? 'true' : 'false' }
        : {}),
      ...(attributes.userType === USER_TYPE.AGENT
        ? { 'custom:groupName': COGNITO_GROUP.AGENT }
        : {}),
    }
    if (Object.keys(updates).length) {
      setIsLoading(true)
      const cognitoUser = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(cognitoUser, updates)
      fetchAuthUser()
    }
  }

  const updatePassword = async (currentValue: string, newValue: string) => {
    /** @TODO try and catch error once AccountSecurity refactored to not expect throw */
    const cognitoUser = await Auth.currentAuthenticatedUser()
    await Auth.changePassword(cognitoUser, currentValue, newValue)
  }

  const forgotPasswordRequest = async ({ email }: { email: string }) => {
    setIsLoading(true)
    try {
      await Auth.forgotPassword(email)
      setResetAccount({ email })
    } catch (error) {
      if (error instanceof Error) {
        /** @TODO Scope error to auth */
        setErrorMessage({
          ...errorMessage,
          passwordResetRequest: error.message || 'Error resetting password',
        })
      }
      setIsLoading(false)
    }
  }

  const forgotPasswordSubmit = async ({
    code,
    newPassword,
  }: {
    code: string
    newPassword: string
  }) => {
    setIsLoading(true)
    try {
      await Auth.forgotPasswordSubmit(resetAccount.email, code, newPassword)
      setResetAccount({ email: '' })
    } catch (error) {
      if (error instanceof Error) {
        /** @TODO Scope error to auth */
        setErrorMessage({
          ...errorMessage,
          passwordResetSubmit: error.message || 'Error resetting password',
        })
      }
      setIsLoading(false)
    }
  }

  const signOut = async () => Auth.signOut()

  const authRedirect = async () => {
    const redirect =
      returnUrl && typeof returnUrl === 'string'
        ? returnUrl
        : getPreferredHomesRoute()

    router.replace(redirect)
  }

  const value = {
    user,
    isAuthenticated: !!user,
    isLoading,
    unverifiedAccount,
    resetAccount,
    isOauth: !!user && user.identityProvider !== null,
    resentConfirmationCode,
    errorMessage,
    federatedRedirectUrl,
    signIn,
    signOut,
    signUp,
    federatedSignIn,
    confirmAccount,
    resendConfirmationCode,
    updateUserAttributes,
    updatePassword,
    forgotPasswordRequest,
    forgotPasswordSubmit,
    authRedirect,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider
