export const HOMES_ROUTE = '/homes'
export const MY_ACRE_ROUTE = '/my-acre'

const PREFERRED_MARKET_LOCAL_STORAGE_KEY = 'preferred-market'

const marketQueryItemRegEx = /^(?<state>[A-Z]{2})\/(?<handle>[a-z]+(?:-[a-z]+)*)$/i

export function getPreferredHomesRoute() {
  const item = localStorage.getItem(PREFERRED_MARKET_LOCAL_STORAGE_KEY);
  if (item === null) return '/homes'

  let market: string | undefined = undefined;
  try {
    market = JSON.parse(item);
  } catch {
    localStorage.removeItem(PREFERRED_MARKET_LOCAL_STORAGE_KEY)
  }
  if (typeof market !== 'string' || marketQueryItemRegEx.exec(market) === null) return '/homes'

  return `/homes/${market}`
}
