// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const branch = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF ?? 'development'
const environmentName = branch === 'main' ? 'production' : branch

const isDevelopment = environmentName === 'development'
const isStaging = environmentName === 'staging'
const isProduction = environmentName === 'production'

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: isProduction ? 0.2 : isStaging ? 1 : 0,
  sampleRate: isDevelopment ? 0 : 1,
  environment: environmentName,
})
