import { API } from '@aws-amplify/api'
import { useQuery } from 'react-query'
import { useSetAtom } from 'jotai'

import {
  customerUniqueIdAtom,
  customerFavoriteListings,
} from '../../utils/atoms'

import useAuth from '../../hooks/useAuth'
import { Institution } from '../saveInstitution'

export type Property = {
  address: string
  baths: number | null
  beds: number | null
  id: number | null
  sqft: number | null
  thumbnail: string
}

export type MonthlyPayment = {
  bank: string
  amount: number
  day: number
}

export type Payments = {
  monthly: MonthlyPayment
}

export type Tenant = {
  property: Property
  payments?: Payments
}

export type Offer = {
  status: 'open' | 'won' | 'lost' | 'closed' | null
  listing: Property
}

type HomeOffer = {
  id: number
  status: 'unsubmitted' | 'open' | 'won' | 'lost' | 'closed'
}

export type Approval = {
  id: number | null
  status: 'created' | 'pending' | 'under review' | 'approved' | 'failed' | null
  maxPurchasePrice: number | null
  approved_amount: number | null
  /** @deprecated("2022-06-15") */
  listing_id: number | null
  /** @deprecated("2022-06-15") */
  last_update: string | null
  /** @deprecated("2022-06-15") */
  institutions: Institution[]
}

export type CreditScoreRange = {
  min: number
  max: number
}

export type Budget = {
  cashAvailable?: number
  annualIncome?: number
  creditScoreRange?: CreditScoreRange
  monthlyBudget?: number
}

export enum USER_TYPE {
  AGENT = 'agent',
  CUSTOMER = 'customer',
}

export type CustomerAttribute = {
  id: number
  unique_id: string
  email_address: string | null
  first_name: string | null
  last_name: string | null
  phone_number: string | null
  picture: string | null
  approval: Approval
  /** @deprecated("2022-07-01") */
  offer: Offer
  homeOffer?: HomeOffer
  tenant?: Tenant
  /** @deprecated("2022-10-12") */
  budget: Budget
  type: USER_TYPE
  listings: {
    favorites: {
      id: number
      date: string
    }[]
  }
}

const loadCustomerAttributes = async (): Promise<CustomerAttribute> => {
  const res = await API.get('customer', '/load', {})

  return res.customer
}

export const useCustomer = (
  disabled?: boolean,
  onSuccess?: (data: CustomerAttribute) => void
) => {
  const { isAuthenticated } = useAuth()

  const updateCustomerUniqueId = useSetAtom(customerUniqueIdAtom)
  const updateCustomerFavoriteListings = useSetAtom(customerFavoriteListings)

  return useQuery('customer', loadCustomerAttributes, {
    enabled: !disabled && isAuthenticated,
    onSuccess: (data: CustomerAttribute) => {
      updateCustomerUniqueId(data.unique_id)
      updateCustomerFavoriteListings(data.listings.favorites)

      if (onSuccess) {
        onSuccess(data)
      }
    },
  })
}
