import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import {
  Analytics,
  AnalyticsBrowser,
  Integrations,
} from '@segment/analytics-next'
import { useCustomer } from '../customer/loadAttributes'

type TrackingOptions = {
  integrations?: Integrations
  anonymousId?: string | null | undefined
  timestamp?: Date | string
  context?: object
  traits?: object
}

export type Track = {
  event: string
  properties?: object
  options?: TrackingOptions
  callback?: () => void
}

type Identify = {
  userId?: string
  traits?: object
}

const TrackingContext = createContext<
  | {
      track: (track: Track) => void
      identify: (identify: Identify) => void
      resetTracking: () => void
    }
  | undefined
>(undefined)

const TrackingProvider: FC = ({ children }) => {
  const router = useRouter()
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined)
  const { data: customerData } = useCustomer()

  useEffect(() => {
    const loadAnalytics = async () => {
      const [loadedAnalytics] = await AnalyticsBrowser.load({
        writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY ?? '',
      })
      setAnalytics(loadedAnalytics)
    }

    loadAnalytics()
  }, [])

  useEffect(() => {
    const { email } = (analytics?.user().traits() ?? {}) as {
      email: string | undefined
    }

    analytics?.page(router.asPath, undefined, {
      ...(email ? { contact_email: email } : {}),
    })
  }, [analytics, router.asPath])

  useEffect(() => {
    if (analytics && customerData && customerData.unique_id) {
      analytics.identify(customerData.unique_id.toString(), {
        email: customerData.email_address,
        first_name: customerData.first_name,
        last_name: customerData.last_name,
      })
    }
  }, [analytics, customerData])

  const track = useCallback<(track: Track) => void>(
    ({ event, properties = {}, options = {}, callback }) => {
      const { email } = (analytics?.user().traits() ?? {}) as {
        email: string | undefined
      }

      analytics?.track(
        event,
        {
          ...(email ? { contact_email: email } : {}),
          ...properties,
        },
        options,
        callback
      )
    },
    [analytics]
  )

  const identify = useCallback<(identify: Identify) => void>(
    ({ userId, traits }) => {
      if (userId) {
        analytics?.identify(userId, traits)
      } else {
        analytics?.identify(traits)
      }
    },
    [analytics]
  )

  const resetTracking = useCallback<() => void>(() => {
    analytics?.reset()
  }, [analytics])

  const value = { track, identify, resetTracking }

  return (
    <TrackingContext.Provider value={value}>
      {children}
    </TrackingContext.Provider>
  )
}

const useTracking = () => {
  const context = useContext(TrackingContext)
  if (context === undefined) {
    throw new Error('useTracking must be used within a TrackingProvider')
  }
  return context
}

export { TrackingProvider, useTracking }
