import Script from 'next/script'
import { useEffect, useState } from 'react'
import { API } from '@aws-amplify/api'
import * as Sentry from '@sentry/nextjs'

import { useTracking } from '../services/context/TrackingContext'
import useAuth from '../hooks/useAuth'

import { LOCAL_STORAGE_ZENDESK_JWT } from '../utils/localStorage'

const ZENDESK_KEYS = {
  production: 'ec28fb28-b3a0-46f8-8cee-11c136efa221',
  staging: '4996e9ec-4618-4dbc-bde2-0de3794a0e74',
}

declare global {
  interface Window {
    zE?: (fn: string, arg: string, arg2?: any) => void
  }
}

const isProduction = () =>
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'main'

const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const zendeskAPI = {
  openChat: () => {
    if (window.zE) {
      window.zE('messenger', 'open')
    }
  },
  login: async (callback?: () => void) => {
    let token = ''
    const existingToken = localStorage.getItem(LOCAL_STORAGE_ZENDESK_JWT) ?? ''
    const decodedToken = existingToken ? parseJwt(existingToken) : null
    const existingTokenValid =
      decodedToken && decodedToken.exp * 1000 > new Date().getTime()

    if (existingTokenValid) {
      token = existingToken
    } else {
      try {
        token = await API.get('customer', '/chat_auth', {})
        localStorage.setItem(LOCAL_STORAGE_ZENDESK_JWT, token)

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        Sentry.captureException(error)
        throw new Error(error.response?.data?.error || 'Something went wrong')
      }
    }

    if (window.zE) {
      window.zE(
        'messenger',
        'loginUser',
        (sendToken: (token: string) => void) => {
          if (token) {
            sendToken(token)
          }
          if (callback) {
            callback()
          }
        }
      )
    }
  },
  logout: () => {
    if (window.zE) {
      window.zE('messenger', 'logoutUser')
    }
  },
}

const ChatLauncher = () => {
  const { track } = useTracking()
  const [unreadMessages, setUnreadMessages] = useState(0)
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    let didCancel = false

    if (window.zE && !didCancel) {
      window.zE('messenger:on', 'unreadMessages', (count: number) =>
        setUnreadMessages(count)
      )
    }

    return () => {
      didCancel = true
    }
  }, [])

  const ZENDESK_KEY = isProduction()
    ? ZENDESK_KEYS.production
    : ZENDESK_KEYS.staging

  return (
    <>
      <Script
        id="ze-snippet"
        src={`https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`}
      />
      <button
        className="group fixed bottom-mobile-nav right-16 z-modal mb-16 grid h-64 w-64 place-items-center rounded-full border border-teal-5 bg-teal-3 desktop-nav:bottom-32 desktop-nav:right-32 desktop-nav:mb-0"
        onClick={() => {
          isAuthenticated
            ? zendeskAPI.login(() => zendeskAPI.openChat())
            : zendeskAPI.openChat()
          track({
            event: 'Chat Opened',
          })
        }}
      >
        {unreadMessages ? (
          <div className="absolute top-0 right-0 rounded-[100px] bg-[#e54054] px-6 text-12 text-white shadow">
            {unreadMessages}
          </div>
        ) : null}

        <svg
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Bubble */}
          <path
            d="M33.25 18.2083C33.2554 20.2981 32.7672 22.3597 31.825 24.225C30.7079 26.4603 28.9904 28.3404 26.8651 29.6547C24.7398 30.969 22.2906 31.6657 19.7917 31.6667C17.7019 31.6721 15.6403 31.1839 13.775 30.2417L4.75 33.25L7.75833 24.225C6.81614 22.3597 6.32788 20.2981 6.33333 18.2083C6.3343 15.7095 7.03097 13.2602 8.34531 11.1349C9.65964 9.00958 11.5397 7.29216 13.775 6.17501C15.6403 5.23282 17.7019 4.74457 19.7917 4.75001H20.5833C23.8836 4.93208 27.0007 6.32505 29.3378 8.66221C31.675 10.9994 33.0679 14.1165 33.25 17.4167V18.2083Z"
            fill="#E8F2EE"
            stroke="#054544"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          {/* Center dot */}
          <path
            className="transition-opacity duration-300 group-hover:opacity-0"
            d="M19.5 19.125C19.8452 19.125 20.125 18.8452 20.125 18.5C20.125 18.1548 19.8452 17.875 19.5 17.875C19.1548 17.875 18.875 18.1548 18.875 18.5C18.875 18.8452 19.1548 19.125 19.5 19.125Z"
            fill="#054544"
            stroke="#054544"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          {/* Right dot */}
          <path
            className="transition-transform duration-300 group-hover:translate-y-[-3px]"
            d="M23.875 19.125C24.2202 19.125 24.5 18.8452 24.5 18.5C24.5 18.1548 24.2202 17.875 23.875 17.875C23.5298 17.875 23.25 18.1548 23.25 18.5C23.25 18.8452 23.5298 19.125 23.875 19.125Z"
            fill="#054544"
            stroke="#054544"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          {/* Left dot */}
          <path
            className="transition-transform duration-300 group-hover:translate-y-[-3px] group-hover:translate-x-[1.5px]"
            d="M15.125 19.125C15.4702 19.125 15.75 18.8452 15.75 18.5C15.75 18.1548 15.4702 17.875 15.125 17.875C14.7798 17.875 14.5 18.1548 14.5 18.5C14.5 18.8452 14.7798 19.125 15.125 19.125Z"
            fill="#054544"
            stroke="#054544"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          {/* Smile */}
          <path
            className="opacity-0 transition-all duration-300 [stroke-dashoffset:-97] group-hover:opacity-100 group-hover:[stroke-dashoffset:0]"
            d="M24 20C24 22.2091 22.2091 24 20 24C17.7909 24 16 22.2091 16 20"
            stroke="#054544"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeDasharray="100"
            pathLength="97"
          />
        </svg>
      </button>
    </>
  )
}

export default ChatLauncher
