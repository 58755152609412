import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { LOCAL_STORAGE_GET_APPROVED_REDIRECT } from './localStorage'

export const getApprovedRedirectPathAtom = atomWithStorage<string | null>(
  LOCAL_STORAGE_GET_APPROVED_REDIRECT,
  null
)

export const customerUniqueIdAtom = atom('')
export const customerFavoriteListings = atom<{id: number, date: string}[]>([])
