const config = {
  // Backend config

  customerApiGateway: {
    REGION: process.env.NEXT_PUBLIC_AWS_REGION,
    URL: process.env.NEXT_PUBLIC_CUSTOMER_API_URL,
  },
  listingApiGateway: {
    REGION: process.env.NEXT_PUBLIC_AWS_REGION,
    URL: process.env.NEXT_PUBLIC_LISTING_API_URL,
  },
  approvalApiGateway: {
    REGION: process.env.NEXT_PUBLIC_AWS_REGION,
    URL: process.env.NEXT_PUBLIC_APPROVAL_API_URL,
  },
  offerApiGateway: {
    REGION: process.env.NEXT_PUBLIC_AWS_REGION,
    URL: process.env.NEXT_PUBLIC_OFFER_API_URL,
  },
  cognito: {
    REGION: process.env.NEXT_PUBLIC_AWS_REGION,
    USER_POOL_ID: process.env.NEXT_PUBLIC_USER_POOL_ID,
    APP_CLIENT_ID: process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID,
    HOSTED_DOMAIN: process.env.NEXT_PUBLIC_AUTH_HOSTED_DOMAIN,
    oauth: {
      REDIRECT_SIGNIN: process.env.NEXT_PUBLIC_OAUTH_REDIRECT_SIGNIN,
      REDIRECT_SIGNOUT: process.env.NEXT_PUBLIC_OAUTH_REDIRECT_SIGNOUT,
    },
  },
  storage: {
    REGION: process.env.NEXT_PUBLIC_AWS_REGION,
    CUSTOMER_APPROVAL_ASSETS_BUCKET:
      process.env.NEXT_PUBLIC_CUSTOMER_APPROVAL_ASSETS_BUCKET,
  },
}

export default config
