export const LOCAL_STORAGE_FUNDING_SOURCES_PAYLOAD = 'fundingSourcesPayload'
export const LOCAL_STORAGE_PLAID_INSTITUTION_LINK_TOKEN = 'acre_inst_link_token'
export const LOCAL_STORAGE_USER_TYPE = 'acrehomesweb-user-type'
export const LOCAL_STORAGE_RE_APPROVAL_TYPE = 'acre_re_approval_type'
export const LOCAL_STORAGE_RE_APPROVAL_ID = 'acre_re_approval_id'
export const LOCAL_STORAGE_RE_APPROVAL_LID = 'acre_re_approval_lid'
export const LOCAL_STORAGE_ZENDESK_JWT = 'zendesk-jwt'
export const LOCAL_STORAGE_GET_APPROVED_REDIRECT = 'acre_get_approved_redirect'
export const LOCAL_STORAGE_BUDGET = 'acre_prequalification_budget'

export const clearLocalStorage = () => {
  const itemsToClear = [
    LOCAL_STORAGE_FUNDING_SOURCES_PAYLOAD,
    LOCAL_STORAGE_PLAID_INSTITUTION_LINK_TOKEN,
    LOCAL_STORAGE_USER_TYPE,
    LOCAL_STORAGE_RE_APPROVAL_TYPE,
    LOCAL_STORAGE_RE_APPROVAL_ID,
    LOCAL_STORAGE_RE_APPROVAL_LID,
    LOCAL_STORAGE_ZENDESK_JWT,
    LOCAL_STORAGE_GET_APPROVED_REDIRECT,
    LOCAL_STORAGE_BUDGET,
  ]

  itemsToClear.forEach((key) => localStorage.removeItem(key))
}
